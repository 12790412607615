import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTitle } from "../../App/redux-reducers/contextProvider";
import apiService from "../../logic/apiService";
import { useNavigate, useParams } from "react-router-dom";
import ResidentCard from "../../components/cards/admin/ResidentCard";
import { useGetDataQuery } from "../../logic/apiSlice";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
export default function AdminBewoners() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationID } = useParams();

  const [residents, setResidents] = useState(undefined);
  const [searchValue, setSearchValue] = useState("");
  let { refetch, data: locationData, isSuccess: isLocationSuccess} = useGetDataQuery(`/admin/application/location/${locationID}`);

  useEffect(() => {
    function setTitle() {
        if (!isLocationSuccess)
            return dispatch(updateTitle('Beheren - Bewoners'));

        if (locationData?.locationID !== parseInt(locationID))
            refetch();

        dispatch(updateTitle(`${locationData?.name || "Beheren"} - Bewoners`));
    }

    setTitle();
  }, [isLocationSuccess])



  useEffect(() => {
    apiService.get(`/admin/bewoner/${locationID}/all`).then((res) => {
      if (!res.success)
        return toast.error("Er is iets misgegaan bij het ophalen van de bewoners");
      setResidents(res.residents);
    });
  }, []);

  return (
    <div className="w-full overflow-scroll no-scrollbar">
      {/* Header */}
      <div className="p-4 flex gap-2.5">
        {/* Searchbar */}
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-12 flex-1 rounded border p-2 outline-none"
          name="search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Zoeken..."
          type="search"
        />

        {/* Create button */}
        <button
          onClick={() => navigate(`/beheren/${locationID}/bewoners/create`)}
          className="bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
        >
          Bewoner aanmaken
        </button>
      </div>

      {/* Residents */}
      {!residents ? (
        <LoadingSpinner />
      ) : residents.length === 0 ? (
        <div className="absolute left-1/2 top-1/2 w-auto -translate-x-1/2 -translate-y-1/2 transform">
          <h3 className="text-m m-0 w-full text-start font-bold">
            Geen bewoners gevonden
          </h3>
        </div>
      ) : (
        residents.map((resident) => {
          if (
            searchValue !== "" &&
            !resident.name.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return null;
          }

          return <ResidentCard key={resident.residentID} data={resident} />;
        })
      )}
    </div>
  );
}
