import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { updateTitle } from "../../App/redux-reducers/contextProvider"
import UpdateCard from "../../components/cards/admin/UpdateCard"
import LoadingSpinner from "../../components/ui/LoadingSpinner"
import apiService from "../../logic/apiService"
import { useGetDataQuery } from "../../logic/apiSlice"

export default function AdminUpdate() {
    const dispatch = useDispatch()

    const { data: accountData, isSuccess} = useGetDataQuery("/account/user");
    const [updates, setUpdates] = useState(undefined)
    const [searchValue, setSearchValue] = useState("")
    const [oldSearchValue, setOldSearchValue] = useState(undefined)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true);

    const observer = useRef(); // Ref to hold the observer instance
    const debouncedSearchValue = useDebounce(searchValue, 500); // Debounce for 300ms

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(updateTitle("Beheren - Berichten"))
    }, [])

    useEffect(() => {
        if (![1].includes(accountData.permissionID))
            navigate('/auth/login');
    }, [isSuccess])

    useEffect(() => {
        if (!hasMore) 
            return;
        fetchUpdates(page);
    }, [page])
    
    useEffect(() => {
        fetchUpdates(page);
    }, [debouncedSearchValue]);

    const lastReportObserver = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect(); // Disconnect previous observer
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage((prevPage) => prevPage + 1); // Increment page number to load more Reports
                }
            });

            if (node) observer.current.observe(node); // Observe the last report element
        },
        [hasMore],
    );

    async function fetchUpdates(_page) {
        const hasSearchValueChanged = searchValue !== oldSearchValue;
        setOldSearchValue(searchValue);
    
        if (hasSearchValueChanged) {
            setPage(1);
            setHasMore(true);
            _page = 1;
        }
    
        const res = await apiService.get(`/update/search?searchValue=${searchValue}&page=${_page}`);
        
        if (!res.success)
            return toast.error("Er is iets misgegaan bij het ophalen van de updates");
    
        // If search value changed, reset updates with new data
        if (hasSearchValueChanged) {
            setUpdates(res.updates);
        } else {
            // Concatenate only unique updates to prevent duplicates
            setUpdates((prevUpdates) => {
                const uniqueUpdates = new Map(prevUpdates.map(update => [update.updateID, update]));
                res.updates.forEach(update => uniqueUpdates.set(update.updateID, update));
                return Array.from(uniqueUpdates.values());
            });
        }
    
        // Update hasMore based on the number of updates fetched
        if (res.updates.length < 2) {
            setHasMore(false);
        }
    }
    


    return (
        <div className="no-scrollbar flex w-full flex-col sm:overflow-auto">
            {/* Header */}
            <div className="px-5 py-4 flex gap-2.5 justify-between">
                
                {/* SearchBar */}
                <input
                    className="focus:border-light-secondary bg-light-bg border-light-primary h-12 flex-1 rounded border p-2 outline-none"
                    name="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Zoek op titel..."
                    type="search"
                />

                {/* Create button */}
                <button
                onClick={() => navigate(`/beheren/update/create`)}
                className="bg-light-secondary w-64 text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
                >
                    Nieuw Bericht
                </button>
            </div>

            {/* Berichten */}
            {!updates ? (
                <LoadingSpinner />
            ) : updates.length === 0 ? (
                <div className="absolute left-1/2 top-1/2 w-auto -translate-x-1/2 -translate-y-1/2 transform">
                    <h3 className="text-m m-0 w-full text-start font-bold">
                        Geen berichten gevonden
                    </h3>
                </div>
            ) : (
                updates.map((update, index) => {
                    if (index === updates.length - 1) {

                        // Attach observer to the last report in the filtered array
                        return (
                            <div key={`${index}-${update.updateID}`} ref={lastReportObserver}>
                                <UpdateCard data={update} />
                            </div>
                        );
                    }
                    
                    return <UpdateCard key={`${index}-${update.updateID}`} data={update} />;
                })
            )}
        </div>
    )
}

// Debounce function
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};