import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTitle } from '../App/redux-reducers/contextProvider';
import { useGetDataQuery } from '../logic/apiSlice';
import { useNavigate } from 'react-router-dom';
import apiService from '../logic/apiService';
import { toast } from 'react-toastify';

export default function Beleven() {
    const { data: accountData, isSuccess} = useGetDataQuery("/account/user");
    const { data: promises = []} = useGetDataQuery("/report/promises");
    const { data: residents = []} = useGetDataQuery("/report/residents");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const imageInputRef = React.useRef();

    const [uploadingMedia, setUploadingMedia] = React.useState(false);
    const [formData, setFormData] = React.useState({
        resident: "",
        promise: "",
        date: formatDate(new Date()),
        content: "",
        image: null,
        image_path: null
    })

    function onChange(e) {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    async function uploadImage(e) {
        setUploadingMedia(true);
        const file = e.target.files[0];

        // Is there a file? No? Stop.
        if (!file) {
            setUploadingMedia(false);
            return;
        }
        
        // Check size
        if (file.size > 4 * 1000 * 1000) { // 4MB
            toast.error("Bestand mag niet groter zijn dan 4MB");
            setUploadingMedia(false);
            return;
        }

        // Check type
        if (!file.type.includes('image')) {
            toast.error("Bestand moet een afbeelding zijn. kies een ander bestand");
            setUploadingMedia(false);
            return;
        }

        // Set image
        setFormData(prev => ({
            ...prev,
            image: URL.createObjectURL(file),
        }));

        // Upload image
        const formData = new FormData();
        formData.append('media', file);

        apiService.post('/report/upload', formData).then((res) => {
            if (res.success) {
                setFormData(prev => ({
                    ...prev,
                    image_path: res.path
                }));
                setUploadingMedia(false);
            } else {
                toast.error("Uploaden van afbeelding mislukt");
                setUploadingMedia(false);
            }
        });
    }
    
    function uploadReport() {
        if (uploadingMedia)
            return toast.error("Wacht tot de afbeelding is geüpload");

        const resultCheck = checkData(formData);

        if (!resultCheck.success) 
            return toast.error(resultCheck.message || "Velden mogen niet leeg gelaten worden");

        apiService.post('/report', formData).then((res) => {
            if (res.success) {
                toast.success("Beleving is aangemaakt");
                setFormData({
                    resident: "",
                    promise: "",
                    date: formatDate(new Date()),
                    content: "",
                    image: null,
                    image_path: null
                });
            } else {
                if (res.message === "FIELD_CONTENT_TOO_LONG")
                    return toast.error("Verslag mag niet langer zijn dan 4000 tekens");
                return toast.error("Er is een onbekende fout opgetreden tijdens het aanmaken van de beleving");
            }
        });
    }

    useEffect(() => {
        if (![1,2,3].includes(accountData.permissionID))
            navigate('/beloven');
    }, [isSuccess])

    useEffect(() => {
        dispatch(updateTitle("Beleven"));
    }, [])

    return (
        <div className='mt w-full flex flex-col justify-start overflow-scroll no-scrollbar'>
            <h1 className="text-xl font-bold text-center">Nieuwe beleving aanmaken</h1>

            {/* Input Form */}
            <div className='p-4 rounded w-full flex sm:flex-row flex-col justify-start items-start'>

                {/* Resident */}
                <div className='w-full'>
                    <div className='flex flex-col p-2'>
                        <label className="text-light-text text-sm">Kies een bewoner</label>
                        <select
                            className="focus:border-light-secondary bg-light-bg border-light-primary h-12 w-full rounded border p-2 outline-none"
                            name="resident"
                            value={formData.resident}
                            onChange={(e) => onChange(e)}
                            placeholder="Kies een bewoner"
                        >
                            <option value="" disabled defaultValue>Kies een bewoner</option>
                            {residents.map((resident) => (
                                <option key={resident.residentID} value={resident.residentID}>{resident.name}</option>
                            ))}
                        </select>
                    </div>

                    {/* Promise */}
                    <div className='flex flex-col p-2'>
                        <label className="text-light-text text-sm">Kies een belofte</label>
                        <select
                            className="focus:border-light-secondary bg-light-bg border-light-primary h-12 w-full rounded border p-2 outline-none"
                            name="promise"
                            value={formData.promise}
                            onChange={(e) => onChange(e)}
                            placeholder="Kies een belofte"
                        >
                            <option value="" disabled defaultValue>Kies een belofte</option>
                            {promises.map((promise) => (
                                <option key={promise.promiseID} value={promise.promiseID}>{promise.promiseNumber}. {promise.promiseName}</option>
                            ))}
                        </select>
                    </div>

                    {/* Date */}
                    <div className='flex flex-col p-2'>
                        <label className="text-light-text text-sm">Klopt de datum?</label>
                        <input
                            className="focus:border-light-secondary bg-light-bg border-light-primary h-12 w-full rounded border p-2 outline-none"
                            name="date"
                            value={formData.date}
                            onChange={(e) => onChange(e)}
                            placeholder="Kies een datum en tijd"
                            type="datetime-local"
                        />
                    </div>

                    {/* Content */}
                    <div className='flex flex-col p-2'>
                        <div className="flex w-full flex-row items-center gap-2">
                            <label className="text-light-text text-sm">Schrijf een verslag</label>
                            <span className="text-light-text text-sm">&#8226;</span>
                            <label className={`text-light-text text-sm ${formData.content.length >= 4000 ? 'text-red-500' : ''}`}>{formData.content.length}/4000</label>
                        </div>
                        <textarea
                            className="focus:border-light-secondary bg-light-bg border-light-primary w-full rounded border p-2 outline-none"
                            name="content"
                            value={formData.content}
                            onChange={(e) => onChange(e)}
                            placeholder="Schrijf een verslag"
                            rows={4}
                            maxLength={4000}
                        />
                    </div>
                </div>

                <div className='w-full p-2'>
                    <div className="flex w-full flex-row items-center gap-2">
                        <span className="text-light-text text-sm">Afbeelding</span>
                        <span className="text-light-text text-sm">&#8226;</span>
                        <span className="text-light-text text-sm">Klik om te selecteren</span>
                    </div>
                    {formData.image 
                        ? <img className="w-full object-contain rounded cursor-pointer" src={formData.image} alt="Profile" onClick={() => imageInputRef.current.click()} /> 
                        : <div className="w-full aspect-[1/1] rounded bg-gray-400 animate-pulse cursor-pointer flex justify-center items-center"   onClick={() => imageInputRef.current.click()}>
                            <p>Klik hier om een afbeelding te selecteren</p>
                        </div>
                    }
                    <input  
                        className="hidden"
                        ref={imageInputRef}
                        name="image"
                        onChange={(e) => uploadImage(e)}
                        placeholder="Upload een afbeelding"
                        type='file'
                        accept='image/*'
                    />
                </div>

            </div>

            {/* Submit */}
            <div className='flex flex-col mb-4 px-6 w-full'>
                <button
                    className={`bg-light-secondary text-light-text h-12 rounded font-bold w-full ${uploadingMedia ? 'cursor-not-allowed animate-pulse' : ''}`}
                    onClick={() => uploadReport()}
                >
                    Beleving Aanmaken
                </button>
            </div>
        </div>
    );
}

function checkData({ resident, promise, date, content }) {
    if (!resident || !promise || !date || !content )
        return {success: false, message: 'Velden mogen niet leeg gelaten worden'};
    if (content.length > 4000)
        return {success: false, message: 'Verslag is te lang.'};
    return {success: true};
}

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
}