import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateTitle } from "../../App/redux-reducers/contextProvider";
import { useGetDataQuery } from "../../logic/apiSlice";
import { useParams } from "react-router-dom";
import AdminPromiseCard from "../../components/cards/admin/AdminPromiseCard";
import apiService from "../../logic/apiService";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
export default function AdminBeloften() {
    const { locationID } = useParams();


    let { refetch, data: locationData, isSuccess: isLocationSuccess} = useGetDataQuery(`/admin/application/location/${locationID}`);
    const [promises, setPromises] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        function setTitle() {
            if (!isLocationSuccess)
                return dispatch(updateTitle('Beheren - Beloften'));

            if (locationData?.locationID !== parseInt(locationID))
                refetch();

            dispatch(updateTitle(`${locationData?.name || "Beheren"} - Beloften`));
        }

        setTitle();
    }, [isLocationSuccess])

    useEffect(() => {
        apiService.get(`/admin/promise/${locationID}`).then(res => {
            if (!res.success) 
                return toast.error("Er is iets misgegaan bij het ophalen van de beloften");
            setPromises(res.promises);
        })
    }, [])

    return (
        <div className="w-full overflow-scroll no-scrollbar">
            {promises.map(promise => {
                return <AdminPromiseCard key={promise.promiseID} data={promise} />
            })}
            {promises.length === 0 && <LoadingSpinner />}
        </div>
    )
}