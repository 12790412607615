import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
    return (
        <div className="absolute left-0 top-0 flex h-screen w-screen items-center justify-center">
            <div className="text-light-text flex flex-col items-center justify-center sm:size-full">
                <span className="mb-2 flex items-center justify-center text-center sm:text-xl">
                    Pagina niet gevonden...
                </span>
                <Link
                    to="/beloven"
                    className="bg-light-secondary text-light-text rounded-md p-2 duration-300 ease-in-out hover:brightness-90"
                >
                    Ga Terug!
                </Link>
            </div>
        </div>
    );
}

export default NotFound;
