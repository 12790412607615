import React, { useContext, useState } from "react";
import { MdCheck, MdClose, MdEdit } from "react-icons/md";
import promise_1 from  "../../../assets/promiseIcons/1.png"
import promise_2 from  "../../../assets/promiseIcons/2.png"
import promise_3 from  "../../../assets/promiseIcons/3.png"
import promise_4 from  "../../../assets/promiseIcons/4.png"
import promise_5 from  "../../../assets/promiseIcons/5.png"
import promise_6 from  "../../../assets/promiseIcons/6.png"
import promise_7 from  "../../../assets/promiseIcons/7.png"
import promise_8 from  "../../../assets/promiseIcons/8.png"
import promise_9 from  "../../../assets/promiseIcons/9.png"
import promise_10 from "../../../assets/promiseIcons/10.png"
import apiService from "../../../logic/apiService";
import { toast } from "react-toastify";

export default function PromiseCard({ data }) {
    const [editMode, setEditMode] = useState(false);
    const [localData, setLocalData] = useState({
        promiseID: data.promiseID,
        promiseName: data.promiseName || "",
        subPromise_1: data.subPromise_1 || "",
        subPromise_2: data.subPromise_2 || "",
        subPromise_3: data.subPromise_3 || "",
    });

    function onChange(e) {
        let value = e.target.value;

        setLocalData(prev => ({
            ...prev,
            [e.target.name]: value
        }))
    }

    function saveChanges() {
        // Check if all data has a value
        if (Object.values(localData).some((field) => field === ""))
            return toast.error("Velden mogen niet leeg gelaten worden");

        // Save changes
        apiService.put(`/admin/promise`, localData).then(res => {
            if (!res.success && res.message === "PROMISE_NOT_FOUND")
                return toast.error("Belofte niet gevonden");
            if (!res.success) 
                return toast.error("Er is een fout opgetreden bij het aanpassen van de belofte");

            toast.success("Belofte is aangepast");
            setEditMode(false);
        })
    }


    return (
        <div 
            className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0"
        >
            {/* Content */}
            <div className="flex flex-row gap-2.5">

                {/* Promise Icon */}
                <div className="size-12"><img className="size-12 object-contain" src={getImage(data.promiseNumber)} alt="Profile" /></div>

                {/* Content */}
                <div className="flex w-full flex-col gap-2">

                    {/* Header */}
                    <div className="flex flex-col">
                        <div className="flex w-full flex-row items-start gap-2 justify-between">
                            {editMode 
                                ? <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-5 rounded border p-2.5 outline-none text-base mb-1"
                                    name="promiseName"
                                    value={localData.promiseName}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Hoofdbelofte"
                                    type="text"
                                />
                                
                                :<h5 className="w-full text-base font-bold whitespace-normal break-words">
                                    {localData.promiseName}
                                </h5>
                            }

                            <div>
                                {!editMode 
                                    ? <MdEdit 
                                        onClick={() => setEditMode(!editMode)}
                                        className="text-light-secondary size-5 transition-all duration-300 cursor-pointer" 
                                    />
                                    : <MdClose
                                        onClick={() => setEditMode(!editMode)}
                                        className="text-light-secondary size-5 transition-all duration-300 cursor-pointer" 
                                    />
                                }


                            </div>
                        </div>
                        <div className="flex w-full justify-between flex-row items-center gap-2">
                            <span className="text-light-text text-sm">
                                Belofte {data.promiseNumber}
                            </span>
                            {editMode && 
                                <MdCheck
                                    onClick={() => saveChanges()}
                                    className="text-light-secondary size-5 transition-all duration-300 cursor-pointer" 
                                />
                            }
                        </div>

                    </div>

                    <div className={`overflow-hidden flex w-full max-w-full transition-all duration-300 display-block h-full`}>
                        <p className="flex w-full flex-col whitespace-normal break-words text-sm">
                            {editMode 
                                ? <input
                                    className="w-full focus:border-light-secondary bg-light-bg border-light-primary h-4 rounded border p-2.5 outline-none text-base mb-1"
                                    name="subPromise_1"
                                    value={localData.subPromise_1}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Subbelofte 1"
                                    type="text"
                                />
                                
                                : <p>&#8226; {localData.subPromise_1}</p>
                            }

                            {editMode 
                                ? <input
                                    className="w-full focus:border-light-secondary bg-light-bg border-light-primary h-4 rounded border p-2.5 outline-none text-base mb-1"
                                    name="subPromise_2"
                                    value={localData.subPromise_2}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Subbelofte 2"
                                    type="text"
                                />
                                
                                : <p>&#8226; {localData.subPromise_2}</p>
                            }
                            
                            {editMode 
                                ? <input
                                    className="w-full focus:border-light-secondary bg-light-bg border-light-primary h-4 rounded border p-2.5 outline-none text-base mb-1"
                                    name="subPromise_3"
                                    value={localData.subPromise_3}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Subbelofte 3"
                                    type="text"
                                />
                                
                                : <p>&#8226; {localData.subPromise_3}</p>
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getImage(promiseNumber) {
    switch (promiseNumber) {
        case 1:
            return promise_1;
        case 2:
            return promise_2;
        case 3:
            return promise_3;
        case 4:
            return promise_4;
        case 5:
            return promise_5;
        case 6:
            return promise_6;
        case 7:
            return promise_7;
        case 8:
            return promise_8;
        case 9:
            return promise_9;
        case 10:
            return promise_10;
        default :
            return null
    }
}