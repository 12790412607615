import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useGetDataQuery } from "../../logic/apiSlice";
import { MdLogout, MdBuild, MdOutlineBuild, MdHandshake, MdOutlineHandshake } from "react-icons/md";
import { BiMessage, BiSolidMessage } from "react-icons/bi";
import { TbLayoutDashboard, TbLayoutDashboardFilled } from "react-icons/tb";
import { FaUser, FaRegUser } from "react-icons/fa";
import { RiGroup3Fill, RiGroup3Line } from "react-icons/ri";
function AdminFooter({ logOut }) {
    const location = useLocation();
    let { data: accountData, isSuccess } = useGetDataQuery("/account/user");
    const { locationID } = useParams();
    return (
        <div className="flex w-full flex-col items-end sm:w-20 sm:gap-4">
            
            {/* General Footer */}
            <div className="border-t-light-primary sm:bg-light-bg-shade bg-light-bg-0.95 z-40 flex h-20 w-full flex-row items-center justify-around border-t sm:relative sm:bottom-auto sm:h-auto sm:w-14 sm:flex-col sm:gap-2 sm:rounded-xl sm:border-0 sm:p-2">
                
                {isSuccess && [1].includes(accountData.permissionID) && <Link
                    to="/beheren/applicatie"
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/applicatie") ? (
                        <TbLayoutDashboardFilled className="text-light-secondary size-9" />
                    ) : (
                        <TbLayoutDashboard className="text-light-secondary size-9" />
                    )}
                </Link>}

                {isSuccess && [1].includes(accountData.permissionID) && <Link
                    to="/beheren/update"
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/update") ? (
                        <BiSolidMessage  className="text-light-secondary size-9" />
                    ) : (
                        <BiMessage  className="text-light-secondary size-9" />
                    )}
                </Link>}

                {isSuccess && [1,2].includes(accountData.permissionID) && <Link
                    to={`/beheren/${locationID || accountData.locationID}/users`}
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/users") ? (
                        <FaUser className="text-light-secondary size-9" />
                    ) : (
                        <FaRegUser className="text-light-secondary size-9" />
                    )}
                </Link>}

                {isSuccess && [1,2].includes(accountData.permissionID) && <Link
                    to={`/beheren/${locationID || accountData.locationID}/bewoners`}
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/bewoners") ? (
                        <RiGroup3Fill className="text-light-secondary size-9" />
                    ) : (
                        <RiGroup3Line className="text-light-secondary size-9" />
                    )}
                </Link>}

                {isSuccess && [1,2].includes(accountData.permissionID) && <Link
                    to={`/beheren/${locationID || accountData.locationID}/beloften`}
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/beloften") ? (
                        <MdHandshake className="text-light-secondary size-9" />
                    ) : (
                        <MdOutlineHandshake className="text-light-secondary size-9" />
                    )}
                </Link>}

            </div>

            {/* Desktop Footer (Logout + Admin) */}
            <div className="border-t-light-primary sm:bg-light-bg-shade bg-light-bg-0.95 fixed bottom-0 z-40 hidden h-20 w-full flex-row items-center justify-around border-t sm:relative sm:bottom-auto sm:flex sm:h-auto sm:w-14 sm:flex-col sm:gap-2 sm:rounded-xl sm:border-0 sm:p-2">
                {isSuccess && [1,2].includes(accountData.permissionID) && <Link
                    to={location.pathname.includes(`/beheren`) ? "/beloven" : "/beheren"} 
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/beheren") ? (
                        <MdBuild  className="text-light-secondary size-9" />
                    ) : (
                        <MdOutlineBuild  className="text-light-secondary size-9" />
                    )}
                </Link>}
                <button
                    onClick={() => logOut()}
                    className="sm:hover:bg-light-bg-0.5 hidden sm:flex sm:rounded-lg sm:p-1 sm:duration-300"
                >
                    <MdLogout className="text-light-secondary size-9" />
                </button>
            </div>
        </div>
    );
}

export default AdminFooter;
