import { useEffect, useState } from "react"
import apiService from "../../logic/apiService"
import LocationCard from "../../components/cards/admin/LocationCard"
import { useDispatch } from "react-redux"
import { updateTitle } from "../../App/redux-reducers/contextProvider"
import { useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/ui/LoadingSpinner"
import { toast } from "react-toastify"
import { useGetDataQuery } from "../../logic/apiSlice";
export default function AdminApplication() {
    const dispatch = useDispatch()

    const { data: accountData, isSuccess} = useGetDataQuery("/account/user");
    const [locations, setLocations] = useState(undefined)
    const [searchValue, setSearchValue] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(updateTitle("Beheren - Locaties"))

        apiService.get("/admin/application/locations").then(res => {
            if (!res.success)
                return toast.error("Er is iets misgegaan bij het ophalen van de locaties")
            setLocations(res.locations)
        })
    }, [])

    useEffect(() => {
        if (![1].includes(accountData.permissionID))
            navigate('/auth/login');
    }, [isSuccess])


    return (
        <div className="no-scrollbar flex w-full flex-col sm:overflow-auto">
            {/* Header */}
            <div className="px-5 py-4 flex gap-2.5 justify-between">
                
                {/* SearchBar */}
                <input
                    className="focus:border-light-secondary bg-light-bg border-light-primary h-12 flex-1 rounded border p-2 outline-none"
                    name="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Zoeken..."
                    type="search"
                />

                {/* Create button */}
                <button
                onClick={() => navigate(`/beheren/location/create`)}
                className="bg-light-secondary w-64 text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
                >
                    Locatie aanmaken
                </button>
            </div>

            {/* Locations */}
            {!locations ? (
                <LoadingSpinner />
            ) : locations.length === 0 ? (
                <div className="absolute left-1/2 top-1/2 w-auto -translate-x-1/2 -translate-y-1/2 transform">
                    <h3 className="text-m m-0 w-full text-start font-bold">
                        Geen locaties gevonden
                    </h3>
                </div>
            ) : (
                locations.map((location, index) => {
                    if (searchValue && !location.name.toLowerCase().includes(searchValue.toLowerCase()))
                        return null;
                    
                    return <LocationCard key={location.locationID} data={location} />;
                })
            )}
        </div>
    )
}