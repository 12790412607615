import React, {useContext} from "react";

import { Link, useLocation } from "react-router-dom";
import { MdLogout, MdBuild, MdOutlineBuild  } from "react-icons/md";
import { useSelector } from "react-redux";
import { useGetDataQuery } from "../../logic/apiSlice";

function Header({ logOut }) {
    const title = useSelector((state) => state.context.title);
    let { data: accountData, isSuccess } = useGetDataQuery("/account/user");

    const location = useLocation();

    return (
        <div className="w-full justify-start sm:flex sm:w-20">
            <div className="w-full sm:relative sm:flex sm:h-[calc(100vh-7rem)] sm:w-auto sm:flex-col sm:justify-between">
                <nav className="bg-light-bg sm:bg-transparent border-b-light-primary fixed top-0 z-40 flex h-14 w-full flex-row items-center justify-between border-b px-6 py-2 sm:relative sm:h-auto sm:w-auto sm:rounded-xl sm:border-0 sm:p-2">
                    
                    {/* Logout */}
                    <button onClick={() => logOut()} className="sm:hidden">
                        <MdLogout className="text-light-secondary size-10" />
                    </button>

                    {/* Title */}
                    <h5 className="sm:text-xl break-words text-base font-bold flex justify-center sm:hidden gap-2 items-center overflow-hidden text-nowrap">
                        {title}
                    </h5>

                    {/* Admin Icon */}
                    {isSuccess && [1,2].includes(accountData.permissionID) ? 
                    <Link
                        to={location.pathname.includes(`/beheren`) ? "/beloven" : "/beheren"} 
                        className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300 sm:hidden`}
                    >
                        {location.pathname.includes("/beheren") ? (
                            <MdBuild  className="text-light-secondary size-9" />
                        ) : (
                            <MdOutlineBuild  className="text-light-secondary size-9" />
                        )}
                    </Link> : <div className="size-10"></div>}
                </nav>
            </div>
        </div>
    );
}

export default Header;
