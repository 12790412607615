import React, { useContext, useEffect, useState } from "react";
import {
  MdAdd,
  MdDelete,
  MdEdit,
  MdLock,
  MdSave,
} from "react-icons/md";
import { useGetDataQuery } from "../../../logic/apiSlice";
import apiService from "../../../logic/apiService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function UserCard({ data }) {
  const { locationID } = useParams();

  const { data: permissions = [] } = useGetDataQuery("/account/permissions");
  const { data: accountData } = useGetDataQuery("/account/user");

  const [editAccount, setEditAccount] = useState(false);
  const [residents, setResidents] = useState([]);
  const [selectedResident, setSelectedResident] = useState("");
  const [connectedResidentsUpdated, setConnectedResidentsUpdated] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [localData, setData] = useState({
    name: data.name,
    email: data.email,
    permissionID: data.permissionID,
    linkedResidents: data.linkedResidents,
  });

  function hasDataChanged() {
    if (connectedResidentsUpdated)
      return true

    return (
      data.name !== localData.name ||
      data.email !== localData.email ||
      data.permissionID !== localData.permissionID
    );
  }


  function onChange(e) {
    let value = e.target.value;

    if (e.target.type === "checkbox") value = e.target.checked;

    setData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  }

  useEffect(() => {
    apiService.get(`/admin/bewoner/${locationID}/all`).then((res) => {
      if (!res.success) 
        return toast.error("Er is een onbekende fout opgetreden tijdens het ophalen van de bewoners");

      // Filter out all residents that are already linked
      const residents = res.residents.filter(
        (resident) =>
          !localData.linkedResidents.some(
            (linkedResident) =>
              parseInt(linkedResident.residentID) ===
              parseInt(resident.residentID)
          )
      );
      setResidents(residents);
    });
  }, []);

  function saveAccount(e) {
    e.preventDefault();

    if (!hasDataChanged()) 
      return;

    // Check if all data has a value
    if (Object.values(localData).some((field) => field === ""))
      return toast.error("Velden mogen niet leeg gelaten worden");

    apiService
      .put(`/admin/account/${data.accountID}`, localData)
      .then((res) => {
        if (!res.success && res.message === "ACCOUNT_ALREADY_EXISTS")
          return toast.error("Dit E-mailadres is al in gebruik");
        if (!res.success && res.message === "PERMISSION_TOO_HIGH")
          return toast.error("Je bent niet bevoegd om deze rechten toe te wijzen");
        if (!res.success)
          return toast.error("Er is iets fout gegaan tijdens het opslaan van de gebruiker");


        // Update data with the values of localData
        data.name = localData.name;
        data.email = localData.email;
        data.permissionID = localData.permissionID;
        data.permissionName = permissions.find(
          (permission) => permission.permissionID === localData.permissionID
        ).permissionName;

        setEditAccount(false);

        toast.success("Gebruiker is opgeslagen");
      });
  }

  function resetPassword(e) {
    e.preventDefault();

    apiService.post(`/admin/account/reset/${data.accountID}`).then((res) => {
      if (!res.success)
        return toast.error("Er is een onbekende fout opgetreden tijdens het resetten van het wachtwoord");
      
      toast.success("Er is een mail gestuurd om het wachtwoord te resetten");
    });
  }

  function deleteAccount(e) {
    e.preventDefault();

    apiService.delete(`/admin/account/${data.accountID}`).then((res) => {
      if (!res.success)
        return toast.error("Er is een onbekende fout opgetreden tijdens het verwijderen van het account");
      toast.success("Account is verwijderd");
      setIsDeleted(true)
    });
  }

  function addResident(residentID) {
    if (residentID === "") return;
    const resident = residents.find(
      (resident) => parseInt(resident.residentID) === parseInt(residentID)
    );

    // Filter out the resident from the list
    if (!resident) 
      return;

    setConnectedResidentsUpdated(true);

    setResidents((prev) =>
      prev.filter(
        (resident) => parseInt(resident.residentID) !== parseInt(residentID)
      )
    );

    setData((prev) => ({
      ...prev,
      linkedResidents: [
        ...prev.linkedResidents,
        { residentID: residentID, name: resident.name },
      ],
    }));
    setSelectedResident("");
  }

  function removeResident(residentID) {
    const resident = localData.linkedResidents.find(
      (resident) => parseInt(resident.residentID) === parseInt(residentID)
    );

    setConnectedResidentsUpdated(true);

    setData((prev) => ({
      ...prev,
      linkedResidents: prev.linkedResidents.filter(
        (resident) => parseInt(resident.residentID) !== parseInt(residentID)
      ),
    }));

    setResidents((prev) => [...prev, resident]);
  }

  if (isDeleted)
    return null;

  return (
    <div className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0 transition-all duration-300">
      {/* Content */}
      <div className="flex flex-row gap-2.5">
        {/* Content */}
        <div className="flex w-full flex-col gap-2">
          {/* Header */}
          <div className="flex flex-col">
            <div className="flex w-full flex-row items-center gap-2">
              <h5 className="text-base font-bold">{data.name}</h5>
              <div>
                {accountData.accountID === data.accountID 
                  ? <p className="text-light-text text-sm"><span className="text-light-text text-sm">&#8226;</span> Jij</p>
                  : <MdEdit
                    onClick={() => setEditAccount(!editAccount)}
                    className="text-light-secondary size-5 hover:size-6 transition-all duration-300"
                  />
                }
              </div>
            </div>
            <div className="flex w-full flex-row items-center gap-2">
              <span className="text-light-text text-sm">
                {data.locationName}
              </span>
              <span className="text-light-text text-sm">&#8226;</span>
              <span className="text-light-text text-sm">
                {data.permissionName}
              </span>
            </div>
          </div>

          {/* Body */}
          <div className="flex flex-col w-full max-w-full">
            <p className="flex w-full whitespace-normal break-words text-sm">
              E-mail: {data.email}
            </p>
          </div>

          <div
            className={`grid duration-500 ease-in-out ${
              editAccount
                ? "grid-rows-animate-height-open"
                : "grid-rows-animate-height-closed"
            }`}
          >
            <div className="overflow-hidden">
              <div className="overflow-hidden w-full flex flex-row gap-4">
                <div className="w-full flex overflow-hidden flex-col">
                  <p className="text-sm">Naam</p>
                  <input
                    id="1"
                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                    name="name"
                    value={localData.name}
                    onChange={(e) => onChange(e)}
                    placeholder="Naam"
                    type="text"
                    tabIndex={1}
                  />

                  <p className="text-sm">E-mail</p>
                  <input
                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none mb-1"
                    name="email"
                    value={localData.email}
                    onChange={(e) => onChange(e)}
                    placeholder="E-mail"
                    type="email"
                    tabIndex={2}
                  />

                  <div className="mb-4">
                    <p className="text-sm">Rechtengroep</p>
                    {permissions.map((permission, index) => {
                      // Users are not allowed to set higher permissions than their own
                      if (accountData.permissionID > permission.permissionID)
                        return null;

                      return (
                        <div
                          key={permission.permissionID}
                          className="w-full flex items-center justify-start gap-1"
                        >
                          <input
                            id={`permission-${permission.permissionID}`}
                            className="focus:border-light-secondary bg-light-bg border-light-primary size-4 rounded border p-2 outline-none"
                            name="permissionID"
                            checked={
                              localData.permissionID === permission.permissionID
                            }
                            onChange={() =>
                              setData((prev) => ({
                                ...prev,
                                permissionID: permission.permissionID,
                              }))
                            }
                            placeholder="permissionID"
                            type="checkbox"
                            tabIndex={4 + index}
                          />
                          <label
                            htmlFor={`permission-${permission.permissionID}`}
                            className="select-none"
                          >
                            {permission.permissionName}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  <div>
                    {localData.permissionID === 4 && (
                      <div className="flex items-start flex-col w-full gap-1 mb-4">
                        <div className="w-full flex flex-col">
                          {/* Bewoner selection */}
                          <p className="text-sm">Bewoner</p>
                          <div className="flex items-center">
                            <select
                              onChange={(e) =>
                                setSelectedResident(e.target.value)
                              }
                              className="w-full focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border pl-2 outline-none text-base mb-1"
                              value={selectedResident}
                            >
                              <option value="" disabled defaultValue>
                                Kies een bewoner
                              </option>
                              {residents.map((resident) => (
                                <option
                                  key={resident.residentID}
                                  value={resident.residentID}
                                >
                                  {resident.name}
                                </option>
                              ))}
                            </select>

                            {/* Add Button */}
                            <div className="size-8 flex justify-center items-center cursor-pointer">
                              <MdAdd
                                className="size-6"
                                onClick={() => addResident(selectedResident)}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Linked Residents */}
                        <div className="overflow-scroll no-scrollbar w-full">
                          {localData.linkedResidents.map((resident) => (
                            <div
                              key={resident.residentID}
                              className="flex items-center gap-1 justify-between w-full"
                            >
                              <p>{resident.name}</p>
                              <div className="size-8 flex justify-center items-center cursor-pointer">
                                <MdDelete
                                  className="size-6"
                                  onClick={() =>
                                    removeResident(resident.residentID)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                </div>
              </div>

              <div className="flex-col md:flex-row flex justify-between items-center gap-5">
                {/* Save */}
                <button
                  type="submit"
                  disabled={!hasDataChanged()}
                  onClick={(e) => saveAccount(e)}
                  tabIndex={permissions.length + 4}
                  className={`${hasDataChanged() ? 'hover:bg-light-accent' : 'cursor-not-allowed opacity-70'} w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold transition-all duration-300`}
                >
                  <MdSave className="fill-light-text size-5" /> Opslaan
                </button>

                {/* Reset Password */}
                <button
                  onClick={(e) => resetPassword(e)}
                  type="button"
                  tabIndex={permissions.length + 5}
                  className="w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
                >
                  <MdLock className="fill-light-text size-5" /> Wachtwoord
                  wijzigen
                </button>

                {/* Delete */}
                <button
                  onClick={(e) => deleteAccount(e)}
                  type="button"
                  tabIndex={permissions.length + 6}
                  className="w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
                >
                  <MdDelete className="fill-light-text size-5" /> Verwijderen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
