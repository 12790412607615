import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { updateTitle } from '../App/redux-reducers/contextProvider';
import { useGetDataQuery } from '../logic/apiSlice';
import { useNavigate } from 'react-router-dom';
import apiService from '../logic/apiService';
import Report from '../components/cards/ReportCard';
import { FaFilter } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import LoadingSpinner from '../components/ui/LoadingSpinner';
import { toast } from 'react-toastify';
export default function Bekijken() {
    const { data: accountData, isSuccess} = useGetDataQuery("/account/user");
    const { data: promises = []} = useGetDataQuery("/report/promises");
    let { data: residents = []} = useGetDataQuery("/report/residents");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [reports, setReports] = useState(undefined);
    const [reportIncrement, setReportIncrement] = useState(1);
    const [filteredReports, setFilteredReports] = useState([]);
    const [hasMore, setHasMore] = useState(true); // Track if there are more reports to load
    const observer = useRef(); // Ref to hold the observer instance
    const [filterMenu, setFilterMenu] = useState(false)


    const [filters, setFilters] = useState({
        residents: [],
        promises: [],
        dates: [],
    })

    useEffect(() => {
        if (![1,2,3,4].includes(accountData.permissionID))
            navigate('/beloven');
    }, [isSuccess])

    useEffect(() => {
        dispatch(updateTitle("Bekijken"));
        fetchReports(1); // Running this will get the first batch of reports
    }, [])

    // Fetch more Reports when reportIncrement changes
    useEffect(() => {
        if (reportIncrement === 1 || !hasMore) return; // Skip the initial page load

        fetchReports(reportIncrement);
    }, [reportIncrement]);

    // Intersection observer callback to detect when the last report is visible
    const lastReportObserver = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect(); // Disconnect previous observer

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setReportIncrement((prevIncrement) => prevIncrement + 1); // Increment page number to load more Reports
                }
            });

            if (node) observer.current.observe(node); // Observe the last report element
        },
        [hasMore],
    );

    // Function to fetch Reports
    async function fetchReports(increment) {
        apiService.get(`/report/${increment}`, {}).then((res) => {
            if (!res.success) 
                return toast.error('Er is iets misgegaan bij het ophalen van de belevingen');

            setReports((prevReports) => [...(prevReports || []), ...res.result]); // Append new Reports directly

            // If fewer than 15 Reports are returned, assume there are no more Reports to load
            if (res.result.length < 15) {
                setHasMore(false);
            }
        });
    };

    useEffect(() => {
        if (!reports) return;
        setFilteredReports(reports.filter((report) => {
            if (filters.residents.length !== 0 && !filters.residents.some((resident) => resident.residentID === report.residentID)) return false;
            if (filters.promises.length !== 0 && !filters.promises.some((promise) => promise.promiseID === report.promiseID)) return false;
            if (filters.dates.length !== 0 && !filters.dates.some((date) => date.name === report.dateCreated.split("T")[0])) return false;
            return true;
        }));        
    }, [reports, filters])

    function handleFilter(e, filter) {
        const value = e.target.value

        const filterValue = JSON.parse(value);

        setFilters(prev => ({
            ...prev,
            [filter]: [...prev[filter], filterValue]
        }));
    }

    function removeFilter(filter, value) {
        setFilters(prev => ({
            ...prev,
            [filter]: prev[filter].filter((filterValue) => filterValue !== value)
        }))
    }

    return (
        <>
            <div className="no-scrollbar flex w-full flex-col sm:overflow-auto">
                <div className="px-5 py-4 flex gap-2.5 justify-between">

                    {/* Filter */}
                    <button
                        className="size-12 flex justify-center items-center sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300"
                        onClick={() => setFilterMenu(!filterMenu)}
                    > 
                        <FaFilter className="text-light-secondary size-8" />
                    </button>

                    {/* Filter List */}
                    <div className="w-full flex justify-start flex-row items-center gap-2 overflow-scroll no-scrollbar">
                        {Object.keys(filters).length !== 0 && Object.keys(filters).map((filter) => {
                            return filters[filter].map((value, index) => {
                                return (
                                    <div key={`${filter}${index}`} onClick={() => removeFilter(filter, value)} className="cursor-pointer hover:px-2 rounded-lg text-nowrap bg-light-primary flex items-center gap-1 h-6 py-0.5 px-1.5 border border-transparent text-sm text-white transition-all shadow-sm">
                                        <span>{value.name || value.promiseName}</span>
                                        <span><MdClose className="size-3.5 hover:size-4"/></span>
                                    </div>
                                )
                            })
                        })}
                    </div>
                </div>

                {/* Filter menu */}
                <div className={`px-5 grid duration-500 ease-in-out ${filterMenu ? "grid-rows-animate-height-open" : "grid-rows-animate-height-closed"}`}>
                    <div className="overflow-hidden w-full flex flex-col sm:flex-row gap-2.5">

                        {/* If user has permissionID 4 (naasten) it wont render */}
                        {accountData.permissionID !== 4 && <div className="w-full flex flex-col">
                            <p className="text-sm">Bewoner</p>
                            <select
                                className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border pl-2 outline-none text-base mb-1"
                                name="resident"
                                value="null"
                                onChange={(e) => handleFilter(e, 'residents')}
                                placeholder="Bewoner"
                                type="text"
                            >
                                <option value="null" disabled>Bewoner</option>
                                {residents.map((resident) => (
                                    <option key={resident.residentID} value={JSON.stringify(resident)}>{resident.name}</option>
                                ))}
                            </select>
                        </div>}

                        <div className="w-full flex flex-col">
                            <p className="text-sm">Belofte</p>
                            <select
                                className="w-full focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border pl-2 outline-none text-base mb-1 overflow-hidden text-ellipsis"
                                name="promise"
                                value="null"
                                onChange={(e) => handleFilter(e, 'promises')}
                                placeholder="Belofte"
                                type="text"
                            >
                                <option value="null" disabled>Belofte</option>
                                {promises.map((promise) => (
                                    <option key={promise.promiseID} value={JSON.stringify(promise)}>{promise.promiseName}</option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full flex flex-col">
                            <p className="text-sm">Datum</p>
                            <input
                                className="w-full focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                name="date"
                                value={""}
                                onChange={(e) => handleFilter({target: {value: JSON.stringify({name: e.target.value})}}, 'dates')}
                                placeholder="Datum"
                                type="date"
                            />
                        </div>
                    </div>
                </div>



                {!reports ? (
                    <LoadingSpinner />
                ) : filteredReports.length === 0 ? (
                    <div className="absolute left-1/2 top-1/2 w-auto -translate-x-1/2 -translate-y-1/2 transform">
                        <h3 className="text-m m-0 w-full text-start font-bold">
                            Geen belevingen gevonden
                        </h3>
                    </div>
                ) : (
                    filteredReports.map((report, index) => {
                        if (index === filteredReports.length - 1) {

                            // Attach observer to the last report in the filtered array
                            return (
                                <div key={report.reportID} ref={lastReportObserver}>
                                    <Report data={report} />
                                </div>
                            );
                        }
                        
                        return <Report key={report.reportID} data={report} />;
                    })
                )}
            </div>
        </>
    );
}