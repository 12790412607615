import React, { useState } from "react";
import { MdDelete, MdEdit, MdHandshake, MdSave } from "react-icons/md";
import { useGetDataQuery } from "../../../logic/apiSlice";
import apiService from "../../../logic/apiService";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { RiGroup3Fill } from "react-icons/ri";
import { toast } from "react-toastify";

export default function ResidentCard({ data }) {
    const navigate = useNavigate();

    const { data: permissions = [] } = useGetDataQuery("/account/permissions");

    const [editLocation, setEditLocation] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [localData, setData] = useState({
        name: data.name,
        contactPerson: data.contactPerson,
        contactPhone: data.contactPhone,
        contactEmail: data.contactEmail,
        address: data.address,
        houseNumber: data.houseNumber,
        postalCode: data.postalCode,
        city: data.city,
    });

    function onChange(e) {
        let value = e.target.value;

        if (e.target.type === 'checkbox')
            value = e.target.checked;

        setData(prev => ({
            ...prev,
            [e.target.name]: value
        }))
    }


    function saveLocation(e) {
        e.preventDefault();

        // Check if all data has a value
        if (!localData.name)
            return toast.error("Velden mogen niet leeg gelaten worden");

        apiService.put(`/admin/application/${data.locationID}`, localData).then((res) => {
            if (!res.success)
                return toast.error("Er is een onbekende fout opgetreden tijdens het opslaan van de locatie");

            const location = res.location;

            // Update data with the values of localData
            data.name = location.name;
            data.contactPerson = location.contactPerson;
            data.contactPhone = location.contactPhone;
            data.contactEmail = location.contactEmail;
            data.address = location.address;
            data.houseNumber = location.houseNumber;
            data.postalCode = location.postalCode;
            data.city = location.city;

            setEditLocation(false);
            toast.success("Locatie is opgeslagen");
        });
    }

    function deleteLocation(e) {
        e.preventDefault();

        apiService.delete(`/admin/application/${data.locationID}`).then((res) => {
            if (!res.success)
                return toast.error("Er is een onbekende fout opgetreden tijdens het verwijderen van de locatie");
            toast.success("Locatie is verwijderd");
            setIsDeleted(true)
        });
    }

    if (isDeleted)
        return null;

    return (
        <div 
            className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3  last:border-b-0 sm:flex-shrink-0 sm:last:mb-0 transition-all duration-300"
        >
            {/* Content */}
            <div className="flex flex-row gap-2.5">

                {/* Content */}
                <div className="flex w-full flex-col gap-2">

                    {/* Header */}
                    <div className="flex flex-row gap-2">

                        <div>
                            <div className="flex w-full flex-row items-center gap-2">
                                <h5 className="text-base font-bold">
                                    {data.name}
                                </h5>
                                <div>
                                    <MdEdit 
                                        onClick={() => setEditLocation(!editLocation)}
                                        className="text-light-secondary size-5 hover:size-6 transition-all duration-300" 
                                    />
                                </div>
                            </div>
                            <div className="flex w-full flex-row items-center gap-2">
                                <span className="text-light-text sm:text-sm text-xxs">{data.address} {data.houseNumber}</span>
                                <span className="text-light-text sm:text-sm text-xxs">&#8226;</span>
                                <span className="text-light-text sm:text-sm text-xxs">{data.city}</span>
                            </div>

                            <div className="flex w-full flex-row items-center gap-2">
                                <span className="text-light-text sm:text-sm text-xxs">{data.contactPerson}</span>
                                <span className="text-light-text sm:text-sm text-xxs">&#8226;</span>
                                <span className="text-light-text sm:text-sm text-xxs hover:underline"><a href={`mailTo:${data.contactEmail}`}>{data.contactEmail}</a></span>
                                <span className="text-light-text sm:text-sm text-xxs">&#8226;</span>
                                <span className="text-light-text sm:text-sm text-xxs hover:underline"><a href={`tel:${data.contactPhone}`}>{data.contactPhone}</a></span>
                            </div>
                        </div>

                    </div>

                    {/* Editing */}
                    <div className={`grid duration-500 ease-in-out ${editLocation ? "grid-rows-animate-height-open" : "grid-rows-animate-height-closed"}`}>
                        <div className="overflow-hidden w-full flex flex-col">
                            <div className="w-full flex flex-col mb-4">

                                <p className="text-sm">Locatie naam</p>
                                <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                    name="name"
                                    value={localData.name}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Naam"
                                    type="text"
                                />
                                <p className="text-sm">Contactpersoon naam</p>
                                <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                    name="contactPerson"
                                    value={localData.contactPerson}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Contactpersoon"
                                    type="text"
                                />

                                <p className="text-sm">Contact e-mail</p>
                                <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                    name="contactEmail"
                                    value={localData.contactEmail}
                                    onChange={(e) => onChange(e)}
                                    placeholder="E-mail"
                                    type="text"
                                />

                                <p className="text-sm">Contact telefoonnummer</p>
                                <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                    name="contactPhone"
                                    value={localData.contactPhone}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Telefoon nummer"
                                    type="text"
                                />

                                <p className="text-sm">Adres</p>
                                <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                    name="address"
                                    value={localData.address}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Adres"
                                    type="text"
                                />
                                <p className="text-sm">Huisnummer</p>
                                <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                    name="houseNumber"
                                    value={localData.houseNumber}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Huisnummer"
                                    type="text"
                                />

                                <p className="text-sm">Postcode</p>
                                <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                    name="postalCode"
                                    value={localData.postalCode}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Postcode"
                                    type="text"
                                />
                                <p className="text-sm">Plaats</p>
                                <input
                                    className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                    name="city"
                                    value={localData.city}
                                    onChange={(e) => onChange(e)}
                                    placeholder="Plaats"
                                    type="text"
                                />
                            </div>

                            <div className="flex flex-col justify-between items-center gap-5 w-full">

                                <div className="flex-col md:flex-row flex justify-between items-center gap-5 w-full">

                                    {/* Users */}
                                    <button
                                        onClick={(e) => navigate(`/beheren/${data.locationID}/users`)}
                                        tabIndex={permissions.length + 4}
                                        className={`w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300`}
                                    >
                                        <FaUser className="fill-light-text size-5"/> Accounts
                                    </button>

                                    {/* Residents */}
                                    <button
                                        onClick={(e) => navigate(`/beheren/${data.locationID}/bewoners`)}
                                        tabIndex={permissions.length + 6}
                                        className="w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
                                    >
                                        <RiGroup3Fill className="fill-light-text size-5"/> Bewoners
                                    </button>

                                    {/* Beloftes */}
                                    <button
                                        onClick={(e) => navigate(`/beheren/${data.locationID}/beloften`)}
                                        tabIndex={permissions.length + 6}
                                        className="w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
                                    >
                                        <MdHandshake className="fill-light-text size-5"/> Beloften
                                    </button>
                                </div>

                                <div className="flex-col md:flex-row flex justify-between items-center gap-5 w-full">
                                    {/* Save */}
                                    <button
                                        onClick={(e) => saveLocation(e)}
                                        tabIndex={permissions.length + 4}
                                        className={`w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300`}
                                    >
                                        <MdSave className="fill-light-text size-5"/> Opslaan
                                    </button>

                                    {/* Delete */}
                                    <button
                                        onClick={(e) => deleteLocation(e)}
                                        tabIndex={permissions.length + 6}
                                        className="w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
                                    >
                                        <MdDelete className="fill-light-text size-5"/> Verwijderen
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}