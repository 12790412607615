import React from 'react';

export const FormatContent = (text) => {
    const parts = text.split(/(\s+)/); // Split by spaces and preserve spaces

    return (
        <span>
            {parts.map((part, index) => {
                if (isValidUrl(part)) {
                    const url = part.startsWith('www.') ? `https://${part}` : (part.startsWith('http://') || part.startsWith('https://') ? part : `https://${part}`);
                    return (
                        <a 
                            href={url} 
                            className='text-blue-500 cursor-pointer hover:underline' 
                            key={index} 
                            onClick={(event) => handleLinkClick(event, url)} // Attach click handler
                        >
                            {part}
                        </a>
                    );
                }

                return <span key={index}>{part}</span>; // Return for parts that don't match the condition
            })}
        </span>
    );
};

function isValidUrl(part) {
    // Regex pattern to validate URL
    const urlPattern = /^(https?:\/\/|www\.)[a-zA-Z0-9.-]+\.(com|nl|org|net|edu|gov|info|io|co|biz)(\/[^\s]*)?$/;
    return urlPattern.test(part);
}

function handleLinkClick(event, url) {
    event.preventDefault();
    const confirmLeave = window.confirm("Weet u zeker dat u onze pagina wilt verlaten en naar een andere site wilt gaan? Deze site kan onveilig zijn.");

    if (confirmLeave) {
        window.open(url, "_blank", "noopener,noreferrer"); // Open the link in a new tab
    }
};