import React, { useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { useGetDataQuery } from "../../logic/apiSlice";
import { MdDelete, MdEdit } from "react-icons/md";
import apiService from "../../logic/apiService";
import { toast } from "react-toastify";
import formatDate from "../../util/formatDate";
import { FormatContent } from "../../util/textFormatter";
import { useNavigate } from "react-router-dom";
export default function Report({ data }) {
    const [showImage, setShowImage] = useState(false);

    const [isDeleted, setIsDeleted] = useState(false);

    const { isSuccess, data: accountData} = useGetDataQuery("/account/user");
    const navigate = useNavigate();
    const [editMode, setEditMode] = useState(false);

    const [content, setContent] = useState(data.content);
    const [editedContent, setEditedContent] = useState(data.content);
    const [isExpanded, setIsExpanded] = useState(false);

    const [timeStamp, setTimeStamp] = useState("");
    const [fullDateWithSeconds, setFullDateWithSeconds] = useState("");

    function updateContent() {

        if (editedContent === content)
            return

        if (editedContent === "")
            return toast.error("Verslag mag niet leeg zijn");

        if (editedContent.length > 4000)
            return toast.error("Verslag mag niet langer zijn dan 4000 karakters");

        apiService.put(`/report/${data.reportID}`, {content: editedContent}).then(res => {
            if (!res.success)
                return toast.error("Er is een onbekende fout opgetreden tijdens het updaten van het verslag");

            toast.success("Verslag succesvol geüpdatet");
            setContent(editedContent);
            setEditMode(false);
        })
    }

    function deleteReport() {
        apiService.delete(`/report/${data.reportID}`).then(res => {
            if (!res.success)
                return toast.error("Er is een onbekende fout opgetreden tijdens het verwijderen van het rapport");
            toast.success("Rapport succesvol verwijderd");
            setIsDeleted(true);
        })
    }

    useEffect(() => {
        const { timeStamp, fullDate } = new formatDate().formatToTimeAgo(data.dateCreated);

        setTimeStamp(timeStamp);
        setFullDateWithSeconds(fullDate);
    }, [data]);

    useEffect(() => {
        if (showImage || editMode) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showImage, editMode]);

    if (isDeleted) 
        return null;

    return (
        <div 
            className="animate-fadeIn border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0"
        >
            {/* Content */}
            <div className="flex flex-row gap-2.5">

                {/* Content */}
                <div className="flex w-full flex-col gap-2">

                    {/* Header */}
                    <div className="flex flex-row gap-4 items-center">

                        {/* Profile Picture */}
                        <div>
                            {data.residentProfile ? <img className="size-12 rounded-full object-cover aspect-[1/1]" src={`${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://familieapp.kwaliteitinhuis.nl/api/media'}/${data.residentProfile}`} alt="Profile" /> : <div className="size-12 rounded-full bg-gray-400"></div>}
                        </div>

                        <div className="flex flex-col">

                            {/* Resident Name */}
                            <div className="flex w-full flex-row items-center gap-2">
                                <h5 className="text-base font-bold">
                                    {data.residentName}
                                </h5>
                            </div>

                            {/* Promise */}
                            <div className="flex w-full flex-row items-center gap-2">
                                <span className="text-light-text-0.5 text-sm hover:underline" onClick={() => navigate(`/beloven/${data.promiseNumber}`)}>{data.promiseName}</span>
                            </div>
                        </div>
                    </div>

                    {/* Body */}
                    <div className="flex sm:flex-row flex-col w-full gap-5 justify-between">

                        {/* Content */}
                        <div className={`w-full flex overflow-hidden flex-shrink`}>
                            <p className="w-full whitespace-pre-wrap break-words" lang="nl">

                                {isExpanded || content.length <= 1000 
                                    ? FormatContent(content)
                                    : FormatContent(content.slice(0, 1000).trim())
                                }
                                
                                {content.length > 1000 && (
                                    <>
                                        <span>{isExpanded ? ' ' : '... '}</span>
                                        <span onClick={() => setIsExpanded(!isExpanded)} className="text-light-text-0.5 hover:underline cursor-pointer">
                                            {isExpanded ? 'Lees minder' : 'Lees meer'}
                                        </span>
                                    </>
                                )}
                            </p>
                        </div>

                        {/* Image */}   
                        {data.media && 
                            <div 
                                className="w-52 flex justify-center items-center sm:items-start flex-grow-0 object-contain overflow-hidden cursor-pointer" 
                                onClick={() => {data.media && setShowImage(!showImage)}}
                            >
                                {data.media && 
                                    <img
                                        src={`${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://familieapp.kwaliteitinhuis.nl/api/media'}/${data.media}`}
                                        className="rounded max-w-full max-h-full hover:scale-105 transition-all duration-300 object-contain"
                                        alt="media"
                                        loading="lazy"
                                    />}
                            </div>
                        }

                    </div>

                    {/* Footer */}
                    <div className="flex flex-col">

                        {/* Date & Promise */}
                        <div className="flex w-full flex-row justify-between titems-center gap-2 items-center">
                            <div className="flex gap-2 items-center">
                                <span
                                    className="text-light-text-0.5 text-sm"
                                    title={fullDateWithSeconds}
                                >
                                    {timeStamp}
                                </span>
                                <span className="text-light-text-0.5 text-sm">&#8226;</span>
                                <span className="text-light-text-0.5 text-sm">Geplaatst door {data.createdBy.split(" ")[0]}</span>                                
                            </div>
                            {isSuccess && [1,2].includes(accountData.permissionID) && (
                                <div className="flex gap-2 items-center">
                                    <MdEdit className="text-light-text-0.5 size-4 cursor-pointer" onClick={() => setEditMode(!editMode)}/>
                                    <span className="text-light-text-0.5 text-sm">&#8226;</span>
                                    <MdDelete 
                                        className="text-light-text-0.5 size-4 cursor-pointer" 
                                        onClick={() => {
                                            if (window.confirm("Weet je zeker dat je deze rapportage wilt verwijderen?")) {
                                            deleteReport();
                                            }
                                        }}
                                        />                                
                                </div>
                            )}
                        </div>
                    </div>

                </div>

                {editMode &&
                    <div className='z-50 fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center animate-fadeIn'>
                        <div className="bg-light-bg-shade rounded p-2 w-11/12 sm:w-144 overflow-hidden">

                            {/* Header */}
                            <div className="flex justify-between items-center">
                                <h5 className="text-lg font-bold w-full">Verslag aanpassen</h5>
                                <div className="rounded-full p-0.5 hover:bg-light-bg-0.5 duration-300" onClick={() => setEditMode(false)}><IoCloseCircle className="z-50 size-8 text-light-secondary"/></div>
                            </div>


                            <div className="flex flex-col">
                                <div className="flex w-full flex-row items-center gap-2">
                                    <label className="text-light-text text-sm">Bewerk het verslag</label>
                                    <span className="text-light-text text-sm">&#8226;</span>
                                    <label className={`text-light-text text-sm ${editedContent.length >= 4000 ? 'text-red-500' : ''}`}>{editedContent.length}/4000</label>
                                </div>
                                <textarea
                                    className="w-full max-h-[calc(100vh-8rem)] min-h-20 h-36 sm:max-h-[calc(100vh-14rem)] focus:border-light-secondary break-words bg-light-bg border-light-primary rounded border p-2 outline-none text-base mb-1"
                                    name="content"
                                    value={editedContent}
                                    onChange={(e) => setEditedContent(e.target.value)}
                                    placeholder="Content"
                                    type="text"
                                    maxLength={4000}
                                />
                                <button
                                    disabled={editedContent === content}
                                    className={`bg-light-secondary text-light-text h-8 rounded font-bold w-full ${editedContent === content ? 'cursor-not-allowed opacity-70' : ''}`}
                                    onClick={() => updateContent()}
                                >Opslaan</button>
                            </div>
                        </div>
                    </div>
                }

                {showImage &&
                    <div className='z-50 fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center animate-fadeIn'>
                        <div className="z-50 w-full h-full absolute" onClick={() => setShowImage(false)}><IoCloseCircle className="size-12 fill-white absolute right-0"/></div>
                        <div className="w-10/12 sm:w-96 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <img src={`${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://familieapp.kwaliteitinhuis.nl/api/media'}/${data.media}`} className={`rounded size-full`}></img>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}