import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiService from "../../logic/apiService";
import { IoIosClose, IoIosCheckmark } from "react-icons/io";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
function ChangePassword() {
    const [credentials, setCredentials] = useState({
        password: "",
        confirmPassword: "",
    });

    const [showFirstPassword, setShowFirstPassword] = useState(false);
    const [showSecondPassword, setShowSecondPassword] = useState(false);


    const { token } = useParams()
    const navigate = useNavigate();

    // Create user account
    function handleSubmit(e) {
        e.preventDefault();

        if (Object.values(credentials).some((field) => field === ""))
            return toast.error("Vul alle velden in");

        if (credentials.password !== credentials.confirmPassword)
            return toast.error("Passwords do not match");

        apiService.post(`/auth/changePassword/${token}`, credentials).then((res) => {
            if (!res.success){
                if (res.message === "INVALID_TOKEN")
                    return toast.error("Uw wachtwoord reset link is onjuist");
                if (res.message === "TOKEN_EXPIRED")
                    return toast.error("Uw wachtwoord reset link is verlopen");
                return toast.error("Veranderen van wachtwoord mislukt");
            }

            toast.success("Wachtwoord succesvol gewijzigd");
            navigate("/beloven");
        });
    }

    // Update credentials state when input changes
    function onChange(e) {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        // Check if token is valid
        apiService.get(`/auth/verify/${token}`).then((res) => {
            if (!res.success) 
                navigate("/auth/login");
        });
    }, [token]);

    return (
        <div className="bg-light-bg text-light-text flex min-h-dvh flex-col items-center justify-center">
            <h1 className="pattaya text-3xl font-bold">Kwaliteit@App</h1>
            <h1 className="pattaya mt-0 mb-5 text-xl font-bold">Wachtwoord wijzigen</h1>

            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-3 flex w-full flex-col items-center justify-center gap-3">
                    <div className="flex w-72 border border-light-primary focus-within:border-light-secondary rounded">
                        <input
                            className="bg-light-bg h-12 w-64 p-2 outline-none rounded-l"
                            name="password"
                            value={credentials.password}
                            onChange={onChange}
                            placeholder="Password"
                            type={showFirstPassword ? "text" : "password"}
                        />
                        <button
                            type="button"
                            className="w-8 flex items-center bg-light-bg rounded-r"
                            onClick={() => setShowFirstPassword(!showFirstPassword)}
                            tabIndex="-1"
                        >
                            <span className="flex items-center border-l-2 border-light-primary pl-2">
                                {showFirstPassword ? (
                                    <FaEyeSlash className="text-light-primary" />
                                ) : (
                                    <FaEye className="text-light-primary" />
                                )}
                            </span>
                        </button>
                    </div>
                    <div
                        className={`grid duration-500 ease-in-out ${credentials.password !== "" ? "grid-rows-animate-height-open" : "grid-rows-animate-height-closed"}`}
                    >
                        <div className="overflow-hidden">
                            <div className="flex w-72 border border-light-primary focus-within:border-light-secondary rounded">
                                <input
                                    className="bg-light-bg h-12 w-64 p-2 outline-none rounded-l"
                                    name="confirmPassword"
                                    value={credentials.confirmPassword}
                                    onChange={onChange}
                                    placeholder="Password"
                                    type={showSecondPassword ? "text" : "password"}
                                />
                                <button
                                    type="button"
                                    className="w-8 flex items-center bg-light-bg rounded-r"
                                    onClick={() => setShowSecondPassword(!showSecondPassword)}
                                    tabIndex="-1"
                                >
                                    <span className="flex items-center border-l-2 border-light-primary pl-2">
                                        {showSecondPassword ? (
                                            <FaEyeSlash className="text-light-primary" />
                                        ) : (
                                            <FaEye className="text-light-primary" />
                                        )}
                                    </span>
                                </button>
                            </div>
                            <div className="mt-2 flex w-72 flex-col">
                                <div
                                    className={`grid duration-300 ease-out ${credentials.password.length >= 8 ? "grid-rows-animate-height-closed mb-0 delay-500" : "grid-rows-animate-height-open mb-1"}`}
                                >
                                    <span
                                        className={`text-light-secondary flex flex-row items-center justify-start overflow-hidden text-sm`}
                                    >
                                        {credentials.password.length >= 8 ? (
                                            <IoIosCheckmark className="size-5 text-green-500" />
                                        ) : (
                                            <IoIosClose className="size-5 text-red-500" />
                                        )}
                                        Wachtwoord moet minimaal 8 karakters lang zijn.
                                    </span>
                                </div>
                                <div
                                    className={`grid duration-300 ease-out ${credentials.password.match(/[A-Z]/) ? "grid-rows-animate-height-closed mb-0 delay-500" : "grid-rows-animate-height-open mb-1"}`}
                                >
                                    <span className="text-light-secondary flex flex-row items-center justify-start overflow-hidden text-sm">
                                        {credentials.password.match(/[A-Z]/) ? (
                                            <IoIosCheckmark className="size-5 text-green-500" />
                                        ) : (
                                            <IoIosClose className="size-5 text-red-500" />
                                        )}
                                        Wachtwoord moet minimaal een hoofdletter bevatten.
                                    </span>
                                </div>
                                <div
                                    className={`grid duration-300 ease-out ${credentials.password.match(/[a-z]/) ? "grid-rows-animate-height-closed mb-0 delay-500" : "grid-rows-animate-height-open mb-1"}`}
                                >
                                    <span className="text-light-secondary flex flex-row items-center justify-start overflow-hidden text-sm">
                                        {credentials.password.match(/[a-z]/) ? (
                                            <IoIosCheckmark className="size-5 text-green-500" />
                                        ) : (
                                            <IoIosClose className="size-5 text-red-500" />
                                        )}
                                        Wachtwoord moet minimaal een kleine letter bevatten.
                                    </span>
                                </div>
                                <div
                                    className={`grid duration-300 ease-out ${credentials.password.match(/[0-9]/) ? "grid-rows-animate-height-closed delay-500" : "grid-rows-animate-height-open"}`}
                                >
                                    <span className="text-light-secondary flex flex-row items-center justify-start overflow-hidden text-sm">
                                        {credentials.password.match(/[0-9]/) ? (
                                            <IoIosCheckmark className="size-5 text-green-500" />
                                        ) : (
                                            <IoIosClose className="size-5 text-red-500" />
                                        )}
                                        Wachtwoord moet minimaal een nummer bevatten.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex w-72 flex-col items-center justify-center gap-3">
                    <button
                        type="submit"
                        className="bg-light-secondary text-light-text h-12 w-full rounded font-bold"
                    >
                        Wijzigen
                    </button>
                    <div className="flex w-full items-center justify-center">
                        <span className="bg-light-primary h-px flex-grow" />
                        <span className="mx-4 -translate-y-px">of</span>
                        <span className="bg-light-primary h-px flex-grow" />
                    </div>
                    <Link
                        to={"/auth/login"}
                        className="border-light-secondary text-light-secondary flex h-12 w-full items-center justify-center rounded border font-bold"
                    >
                        Terug naar inloggen
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default ChangePassword;
