import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function AuthLayout() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the current pathname is exactly "/auth" or "/auth/" and redirect to "/auth/login"
        if (location.pathname === "/auth" || location.pathname === "/auth/") {
            navigate("/auth/login");
        }
    }, [location.pathname, navigate]);

    return <Outlet />;
}

export default AuthLayout;
