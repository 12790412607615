import { createSlice } from "@reduxjs/toolkit";

export const contextSlice = createSlice({
	name: "context",
	initialState: {
		title: "Beloven",
	},
	reducers: {
		updateTitle: (state, action) => {
			state.title = action.payload;
		},
	},
});

export const { updateColorTheme, updateTitle } = contextSlice.actions;

export default contextSlice.reducer;
