import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import apiService from "../../../logic/apiService";
import { toast } from "react-toastify";
import formatDate from "../../../util/formatDate";

export default function UpdateCard({ data }) {
    const [timeStamp, setTimeStamp] = useState("");
    const [fullDateWithSeconds, setFullDateWithSeconds] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);

    function deleteUpdate() {
        apiService.delete(`/update/${data.updateID}`).then(res => {
            if (!res.success)
                return toast.error("Er is een onbekende fout opgetreden tijdens het verwijderen van de update");
            toast.success("Update succesvol verwijderd");
            setIsDeleted(true);
        })
    }

    useEffect(() => {
        const { timeStamp, fullDate } = new formatDate().formatToTimeAgo(data.dateCreated);

        setTimeStamp(timeStamp);
        setFullDateWithSeconds(fullDate);
    }, [data]);

    if (isDeleted) 
        return null;

    return (
        <div 
            className="animate-fadeIn border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0"
        >
            {/* Content */}
            <div className="flex flex-row gap-2.5">

                {/* Content (Removing 4rem because of profilePicture) */}
                <div className="flex w-[calc(100%-4rem)] flex-col gap-2">

                    {/* Header */}
                    <div className="flex flex-col">

                        {/* Resident Name */}
                        <div className="flex w-full flex-row items-center gap-2">
                            <h5 className="text-base font-bold">
                                {data.title}
                            </h5>
                        </div>

                        {/* Date & Promise */}
                        <div className="flex w-full flex-row items-center gap-2">
                            <span
                                className="text-light-text-0.5 sm:text-sm text-xxs"
                                title={fullDateWithSeconds}
                            >
                                {timeStamp}
                            </span>
                            <span className="text-light-text-0.5 sm:text-sm text-xxs">&#8226;</span>
                            <span className={`
                                sm:text-sm text-xxs 
                                ${new Date(data.expirationDate) < new Date() ? 'text-red-500' : 'text-light-text-0.5'}`}
                                title={`Verloopt op ${new formatDate().dateToYYMMDD(data.expirationDate)}`}
                            >
                                {new Date(data.expirationDate) < new Date() 
                                    ? 'verlopen' 
                                    : new formatDate().dateToYYMMDD(data.expirationDate)
                                }
                            </span>
                            <span className="text-light-text-0.5 sm:text-sm text-xxs">&#8226;</span>
                            <MdDelete 
                                className="text-light-text-0.5 sm:text-sm size-4 cursor-pointer" 
                                onClick={() => {
                                    if (window.confirm("Weet je zeker dat je deze update wilt verwijderen?")) {
                                    deleteUpdate();
                                    }
                                }}
                            />                                
                        </div>

                    </div>

                    {/* Body */}
                    <div className="flex sm:flex-row flex-col w-full gap-5 justify-between">
                        <div className={`${data.media && 'sm:w-8/12'} w-full`}>

                            <div className="flex w-full overflow-hidden max-w-full">
                                <p className="w-full whitespace-pre-wrap break-words" lang="nl">
                                    {data.content}
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}