import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetDataQuery } from "../../logic/apiSlice";

import apiService from "../../logic/apiService";
import Footer from "../ui/Footer";
import Header from "../ui/Header";
import { useSelector } from "react-redux";
function Layout() {
    const title = useSelector((state) => state.context.title);
    const { data: accountData } = useGetDataQuery("/account/user");

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            navigate("/beloven");
        }

        if (location.pathname.toLocaleLowerCase() === "/beleven") {
            if (accountData.permissionID === 4)
                navigate("/beloven");
        }
    }, []);

    function logOut() {
        // The Redux Cache is cleared on the loginPage
        apiService.post("/auth/logout").then(() => {
            navigate("/auth/login");
        });
    }

    return (
        <div className="flex-col sm:relative sm:flex h-dvh">
            <span className="text-light-text z-50 hidden font-bold sm:fixed sm:flex sm:h-14 sm:w-full sm:items-center sm:justify-center sm:gap-2 sm:text-xl">
                {title}
            </span>
            <div className="bg-light-bg text-light-text flex h-full flex-col sm:flex-row-reverse sm:items-center sm:justify-center sm:gap-4">
                <Header logOut={logOut} />
                <div className="no-scrollbar sm:bg-light-bg-shade flex w-full flex-col flex-1 overflow-y-auto items-center pt-14 sm:h-[calc(100vh-7rem)] sm:max-w-md sm:justify-start sm:rounded-xl sm:pt-0 sm:shadow-xl md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl">
                    <Outlet />
                </div>
                <Footer logOut={logOut} />
            </div>
        </div>
    );
}

export default Layout;
