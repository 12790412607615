import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../logic/apiService";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { clearApiStore } from "../../App/store";
import { toast } from "react-toastify";

export default function Login() {
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });

    const dispatch = useDispatch();

    const [forgotPassword, setForgotPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(clearApiStore());
        apiService.get("/auth/checkTokenValidity").then((res) => {
            if (res.success)
                navigate("/beloven");
        });
    }, []);

    // Authenticate user
    function handleSubmit(e) {
        e.preventDefault();

        if (forgotPassword) {
            return;
        }

        if (!credentials.email || !credentials.password)
            return toast.error("Velden mogen niet leeg gelaten worden");

        apiService.post("/auth", credentials).then((res) => {
            if (res.success) {
                navigate("/beloven");
            } else {
                if (res.message === "INVALID_CREDENTIALS")
                    return toast.error("Velden mogen niet leeg gelaten worden");
                if (res.message === "INVALID_EMAIL_OR_PASSWORD")
                    return toast.error("Onjuist e-mailadres of wachtwoord");
                if (res.message === "CHANGE_PASSWORD")
                    return toast.error("Uw wachtwoord moet nog worden gewijzigd");
                return toast.error("Er is een onbekende fout opgetreden");
            }
        });
    }

    function resetPassword(e) {
        e.preventDefault();

        apiService.post("/auth/reset/mail", { email: credentials.email }).then((res) => {
            if (res.success) {
                toast.success("Er is een mail gestuurd om het wachtwoord te resetten");
                setForgotPassword(false);
            } else {
                if (res.message === "INVALID_EMAIL")
                    return toast.error("Ongeldig e-mailadres");
                if (res.message === "TOO_MANY_REQUESTS")
                    return toast.error("Er is al een e-mail verzonden. Probeer het over 5 minuten opnieuw. Vergeet niet je spam-folder te controleren");
                return toast.error("Er is iets fout gegaan tijdens het versturen van een reset-mail");
            }
        });

    }

    // Update credentials state when input changes
    function onChange(e) {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    }

    function downloadPrivacyAgreement() {
        apiService.get("/auth/privacy-agreement").then((blob) => {
            // Create a URL for the response Blob
            const url = window.URL.createObjectURL(blob);
            
            // Create a link element
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "privacy-agreement.pdf"); // Set the filename
            
            // Append the link to the body
            document.body.appendChild(link);
            
            // Programmatically trigger the download
            link.click();
            
            // Cleanup: remove the link and revoke the object URL
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url); // Free up memory
            toast.info("De privacyverklaring is gedownload");
        }).catch((error) => {
            toast.error("Er is iets fout gegaan tijdens het downloaden van de privacyverklaring");
        });
    }    

    return (
        <div className="bg-light-bg text-light-text flex min-h-dvh flex-col items-center justify-center">
            <h1 className="pattaya my-6 text-3xl font-bold">Kwaliteit@App</h1>

            <form onSubmit={(e) => handleSubmit(e)}>
                <div className={`flex w-full flex-col items-center justify-center ${forgotPassword ? '' : 'gap-3'}`}>
                    <input
                        className="focus:border-light-secondary bg-light-bg border-light-primary h-12 w-72 rounded border p-2 outline-none"
                        name="email"
                        value={credentials.email}
                        onChange={(e) => onChange(e)}
                        placeholder="E-mail"
                        type="email"
                    />
                    {!forgotPassword && 
                        <div className="flex w-72 border border-light-primary focus-within:border-light-secondary rounded">
                            <input
                                className="bg-light-bg h-12 w-64 p-2 outline-none rounded-l"
                                name="password"
                                value={credentials.password}
                                onChange={onChange}
                                placeholder="Password"
                                type={showPassword ? "text" : "password"}
                            />
                            <button
                                type="button"
                                className="w-8 flex items-center bg-light-bg rounded-r"
                                onClick={() => setShowPassword(!showPassword)}
                                tabIndex="-1"
                            >
                                <span className="flex items-center border-l-2 border-light-primary pl-2">
                                    {showPassword ? (
                                        <FaEyeSlash className="text-light-primary" />
                                    ) : (
                                        <FaEye className="text-light-primary" />
                                    )}
                                </span>
                            </button>
                        </div>
                    }
                </div>
                <div className="flex h-min w-full items-center justify-end pb-5 pt-1">
                    <p className="text-light-accent text-sm hover:underline cursor-pointer" onClick={() => setForgotPassword(!forgotPassword)}>
                        {forgotPassword ? 'Inloggen' : 'Wachtwoord vergeten?'}
                    </p>
                </div>
                <div className="flex w-72 flex-col items-center justify-center gap-3">
                    {!forgotPassword ? <button
                        type="submit"
                        className="bg-light-secondary text-light-text h-12 w-full rounded font-bold"
                    >
                        Login
                    </button>
                    :
                    <button
                        type="button"
                        onClick={(e) => resetPassword(e)}
                        className="bg-light-secondary text-light-text h-12 w-full rounded font-bold"
                    >
                        Mail versturen
                    </button>}
                </div>
            </form>
            <div className="break-words flex w-full justify-center mt-4">
                <p className="text-xxs break-words">
                    Door u aan te melden, gaat u automatisch akkoord met de <span onClick={() => downloadPrivacyAgreement()} className="text-blue-500 hover:underline cursor-pointer">privacyverklaring</span>.
                </p>
            </div>
        </div>
    );
}
