import React, { useEffect, useState } from "react";
import { BiSolidMessage } from "react-icons/bi";
import apiService from "../../logic/apiService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateTitle } from "../../App/redux-reducers/contextProvider";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import '../../markdownStyles.css'
export default function AdminCreateUpdate() {
	const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("edit");
  const [data, setData] = useState({
    title: "",
    content: "",
    expirationDate: formatDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)),
  });

  function onChange(e) {
    let value = e.target.value;

    setData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  }

  useEffect(() => {
    dispatch(updateTitle("Beheren - Nieuw bericht"));
  }, []);

  function saveUpdate(e) {
    e.preventDefault();

    // Check if all data has a value
    if (Object.values(data).some((x) => x === ""))
      return toast.error("Velden mogen niet leeg gelaten worden");

    apiService.post(`/update`, data).then((res) => {
      if (!res.success)
        return toast.error("Er is een onbekende fout opgetreden bij het plaatsen van het bericht");

      toast.success("Bericht is geplaatst");
      navigate(`/beheren/update`);
    });
  }

  return (
    <div className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0">
      <h1 className="text-xl">Nieuw bericht plaatsen</h1>
			<div
        className="overflow-hidden flex-col flex w-full max-w-full transition-all duration-300 display-block h-full"
      >
        
        <p className="text-sm">Titel van het bericht</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="title"
          value={data.title}
          onChange={(e) => onChange(e)}
          placeholder="Titel"
          type="text"
        />

        <p className="text-sm">Tot waneer moet het bericht beschikbaar zijn</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="expirationDate"
          value={data.expirationDate}
          onChange={(e) => onChange(e)}
          placeholder="Date"
          type="date"
        />

        <p className="text-sm">Bericht</p>

        <div className="flex">
          <button             
            className={`bg-light-bg-shade sm:bg-light-bg w-20 text-sm h-full p-2 rounded-tl border-r border-gray-400 ${activeTab === "edit" ? "bg-light-secondary sm:bg-light-secondary" : "sm:hover:bg-light-bg-0.5"}`}
            onClick={() => setActiveTab("edit")}
          >
            Bewerken
          </button>
          <button             
            className={`bg-light-bg-shade sm:bg-light-bg w-20 text-sm h-full p-2 rounded-tr ${activeTab === "preview" ? "bg-light-secondary sm:bg-light-secondary" : "sm:hover:bg-light-bg-0.5"}`}
            onClick={() => setActiveTab("preview")}
          >
            Bekijken
          </button>
        </div>

        {/* Container */}
        <div className="w-full h-full flex-grow overflow-scroll border-light-secondary rounded-b rounded-r border">
          {activeTab === "edit" && (
            <textarea
              className="w-full bg-light-bg h-full border p-2 outline-none text-base"
              name="content"
              value={data.content}
              onChange={(e) => onChange(e)}
              placeholder="Bericht"
            />
          )}
          {activeTab === "preview" && (
            <div className="p-4 markdown-body overflow-scroll">
              <ReactMarkdown>{data.content}</ReactMarkdown>
            </div>
          )}

        </div>

        <div className="flex-col md:flex-row flex justify-between items-center gap-5 mt-2">
          {/* Save */}
          <button
            type="submit"
            onClick={(e) => saveUpdate(e)}
            className={`w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300`}
          >
            <BiSolidMessage className="fill-light-text size-5" /> Plaats update
          </button>
        </div>
      </div>
    </div>
  );
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}