import ReactMarkdown from "react-markdown";
import '../../markdownStyles.css'
import { FaCheck } from "react-icons/fa";
import apiService from "../../logic/apiService";
import { toast } from "react-toastify";
export default function ShowUpdate({ update, setUpdate }) {

    function closeUpdate() {
        apiService.post(`/update/${update.updateID}/seen`).then((res) => {
            if (!res.success)
                return toast.error("Er is een onbekende fout opgetreden bij het sluiten van het bericht");

            setUpdate(false);
        });
        setUpdate(false);
    }

    return (
        <div className="w-full flex-col h-dvh flex justify-center gap-2 items-center">

            {/* Kwaliteit@App */}
            <h5 className="text-light-text text-xl break-words font-bold flex justify-center gap-2 items-center overflow-hidden text-nowrap">
                Kwaliteit@App
            </h5>

            {/* Container */}
            <div className="w-11/12 h-[calc(100dvh-5rem)] border rounded border-light-secondary bg-light-bg-shade sm:w-160">

                {/* Title */}
                <h1 className="text-center text-xl font-bold text-light-text border-b border-light-secondary">{update.title}</h1>

                {/* Markdown Wrapper */}
                <div className="p-2 markdown-body w-full h-[calc(100%-5rem)] overflow-auto">
                    <ReactMarkdown>{update.content}</ReactMarkdown>
                </div>

                {/* Close Button */}
                <button
                    onClick={closeUpdate}
                    className={`w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 font-bold hover:bg-light-accent transition-all duration-300`}
                >
                    Ik snap het!
                </button>
            </div>
        </div>
    )
}