class formatDate {
    formatToTimeAgo(date) {
        const timestampDate = new Date(date);
        const now = new Date();

        // Format full date with time (including seconds) in local time zone
        const formattedFullDateWithSeconds = timestampDate.toLocaleString(
            "nl-NL",
            {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            },
        );

        // Calculate time difference
        const timeDifference = now.getTime() - timestampDate.getTime();
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        if (days >= 1) {
            // Show full date after 24 hours
            return {timeStamp: timestampDate.toLocaleDateString("nl-NL"), fullDate: formattedFullDateWithSeconds};
        } else if (hours > 0) {
            return {timeStamp: `${hours}u geleden`, fullDate: formattedFullDateWithSeconds};
        } else if (minutes > 0) {
            return {timeStamp: `${minutes}m geleden`, fullDate: formattedFullDateWithSeconds};
        } else {
            return {timeStamp: "Zojuist", fullDate: formattedFullDateWithSeconds};
        }

    }

    dateToYYMMDD(date) {
        date = new Date(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${year}-${month}-${day}`;
    }
}

export default formatDate;